/* eslint-disable no-unused-vars */
import { Spinner } from 'components'
import { Button } from 'components/buttons'
import { get, keys, omit } from 'lodash'
import { func, shape } from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Col, CustomInput, Input, Row } from 'reactstrap'
import {
  batchShippingTimeEdit,
  edit,
  getProductsByCollectionId
} from 'redux/service/product-service'
import { isVendor } from 'utils/auth'
import { STATUSES } from 'utils/constants'
import { notifyMappedError } from 'utils/errors'
import { showNotify } from 'utils/toast'

import MerchElements from '../components/merchandising-compoents/merch-elements'
import { getCollectionByVendor } from '../redux/service/collection-service'
import Style from './styles/merchandising.module.css'

class Merchandising extends Component {
  state = {
    leadTme: 0,
    data: [],
    selectedIdsToBatchEdit: {},
    batchShippingInValue: 0,
    showShippingInError: false,
    isBatchEditInProgress: false
  }

  componentDidMount() {
    const vendorId = this.getVendorId()

    getCollectionByVendor(vendorId).then((res) => {
      const ids = res.data.docs.map((collection) => collection._id)

      ids.forEach((item) => {
        getProductsByCollectionId({
          collectionId: item,
          productStatus: STATUSES.APPROVED
        }).then(({ error, products }) => {
          if (error) {
            notifyMappedError(error)
          }

          if (products) {
            const newData = [...this.state.data, ...products]

            this.setState({
              data: newData.sort((a, b) => (a._id < b._id ? 1 : -1))
            })
          }
        })
      })
    })
  }

  getVendorId = () => get(this.props, 'match.params.vendor', '')

  changeSelected = (id) => {
    const { selectedIdsToBatchEdit } = this.state

    this.setState({
      selectedIdsToBatchEdit: selectedIdsToBatchEdit[id]
        ? omit(selectedIdsToBatchEdit, [id])
        : { ...selectedIdsToBatchEdit, [id]: true }
    })
  }

  changeShippingIn = async (id, value) => {
    const { data } = this.state
    for (const el of data) {
      if (el._id === id) {
        el.shippingIn = value
        await edit(el._id, { shippingIn: el.shippingIn })
      }
    }
    this.setState({ data })
    showNotify({ message: `Updated 1 product` })
  }

  changeAcceptDiscount = (id) => {
    const { data } = this.state
    data.forEach((el) => {
      if (el._id === id) {
        el.acceptDiscount = !el.acceptDiscount
        edit(el._id, { acceptDiscount: el.acceptDiscount })
      }
    })
    this.setState({ data })
  }

  batchEditAcceptDiscount = (value) => {
    const { data, selectedIdsToBatchEdit } = this.state

    data.forEach((el) => {
      if (selectedIdsToBatchEdit[el._id]) {
        el.acceptDiscount = value
        edit(el._id, { acceptDiscount: value })
      }
    })

    this.setState({ data })
  }

  changeBatchShippingInValue = (value) => {
    this.setState({
      batchShippingInValue: value,
      showShippingInError: value < 1
    })
  }

  batchEditShippingIn = async () => {
    const { data, selectedIdsToBatchEdit, batchShippingInValue } = this.state

    if (batchShippingInValue < 1 || !batchShippingInValue) {
      this.setState({ showShippingInError: true })
      return
    }
    const itemsToEdit = Object.keys(selectedIdsToBatchEdit)
    const dataItemsToEdit = data
      .filter((item) => itemsToEdit.includes(item._id))
      .map((item) => ({ id: item._id, currentValue: item.shippingIn }))

    this.setState({ isBatchEditInProgress: true })

    const {
      data: { amountModified }
    } = await batchShippingTimeEdit(dataItemsToEdit, batchShippingInValue)
    data.forEach((el) => {
      if (selectedIdsToBatchEdit[el._id]) {
        el.shippingIn = batchShippingInValue
      }
    })
    showNotify({ message: `Updated ${amountModified} products` })
    this.setState({ data, isBatchEditInProgress: false })
  }

  handleSelectAll = (e) => {
    const { checked } = e.target
    const { data } = this.state

    this.setState({
      selectedIdsToBatchEdit: checked
        ? data.reduce((acc, { _id }) => {
            acc[_id] = true

            return acc
          }, {})
        : {}
    })
  }

  shouldShowBatchEdit = () => {
    const { selectedIdsToBatchEdit } = this.state

    return keys(selectedIdsToBatchEdit).length < 2
  }

  render() {
    const {
      intl: { formatMessage },
      authReducer
    } = this.props
    const { data, selectedIdsToBatchEdit } = this.state
    const isUserVendor = isVendor(authReducer)

    const products = !isUserVendor
      ? data
      : data.filter((el) => !el.discontinuedOn)

    const selectAllChecked =
      data.length > 0 && data.length === keys(selectedIdsToBatchEdit).length

    return (
      <div className={Style.main}>
        <Row className={Style.headerRow}>
          <Col md={5}>
            <h3>
              <FormattedMessage id="Merchandising" />
            </h3>
          </Col>
          <Col md={{ size: 4, offset: 3 }} style={{ display: 'flex' }}>
            <div style={{ marginLeft: 'auto' }}>
              {this.shouldShowBatchEdit() && (
                <p>
                  <FormattedMessage id="Select more than one item to batch edit" />
                </p>
              )}
              <CustomInput
                checked={selectAllChecked}
                className={Style.selectAll}
                id="select_all_products"
                label={formatMessage({
                  id: 'Select all products'
                })}
                type="checkbox"
                onChange={this.handleSelectAll}
              />
              <div
                className={`${Style.leadTimeInput} ${
                  this.shouldShowBatchEdit() ? Style.disabled : ''
                }`}
              >
                <span>
                  {formatMessage({
                    id: 'Ships in'
                  })}
                </span>
                <Input
                  className={Style.daysInput}
                  onChange={(e) =>
                    this.changeBatchShippingInValue(e.target.value)
                  }
                  type="number"
                  min="1"
                  invalid={
                    this.state.batchShippingInValue &&
                    this.state.batchShippingInValue < 1
                  }
                />

                <span>
                  {formatMessage({
                    id: 'weeks'
                  })}
                </span>
                <div
                  className={`${
                    this.state.showShippingInError ||
                    this.state.isBatchEditInProgress
                      ? Style.disabled
                      : ''
                  }`}
                >
                  <Button onClick={this.batchEditShippingIn} type="button">
                    {this.state.isBatchEditInProgress ? (
                      <Spinner
                        color="--main-bg-light"
                        width={43}
                        length={3}
                        size={14}
                        show
                      />
                    ) : (
                      formatMessage({
                        id: 'Update'
                      })
                    )}
                  </Button>
                </div>
              </div>
              {this.state.showShippingInError && (
                <div className={Style.error}>
                  {formatMessage({
                    id: 'Please enter a value greater than or equal to 1'
                  })}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          {products.map((product) => (
            <MerchElements
              key={product._id}
              product={product}
              selectedProductIds={selectedIdsToBatchEdit}
              changeAcceptDiscount={this.changeAcceptDiscount}
              changeShippingIn={this.changeShippingIn}
              changeSelected={this.changeSelected}
            />
          ))}
        </Row>
      </div>
    )
  }
}
Merchandising.propTypes = {
  intl: shape({
    formatMessage: func
  }),
  authReducer: shape({})
}
const mapStateToProps = (state) => ({
  vendorReducer: state.vendorReducer,
  authReducer: state.authReducer
})

export default injectIntl(connect(mapStateToProps)(Merchandising))
