import { cloneDeep, get, groupBy, keys, noop, omit, pick, range } from 'lodash'

import {
  CURRENCY_CONVERT,
  ERRORS,
  ORDER_BY,
  STATUSES,
  USER_TYPES,
  WOODEN_CRATE
} from './constants'
import { skuFromProductId } from './vendors'

/**
 * @param {Object} config
 * @param {String} config.lang
 * @param {Object} config.product
 */
export const additionalDataByLang = (config = {}) => {
  const { lang = '', product = {} } = config || {}

  return get(product, 'additionalData', [])
    .filter((pad) => pad.language === lang)
    .map((pad) => omit(pad, ['language']))
}

/**
 * @param {Object} config
 * @param {String} config.language
 * @param {Object} config.values
 */
export const additionalDataFromValues = (config = {}) => {
  const { language = '', values = {} } = config

  return keys(values)
    .filter((valueKey) => valueKey.indexOf('additionalData-') === 0)
    .map((fieldKey) => ({
      key: fieldKey.split('-')[1],
      language,
      value: values[fieldKey]
    }))
}

/**
 * @param {Object} config
 * @param {String} config.lang
 * @param {Object} config.product
 */
export const additionalDataMapped = (config = {}) => {
  const additionalData = additionalDataByLang(config)

  return additionalData.map((pad) => ({
    name: pad.key,
    type: pad.value
  }))
}

/**
 * @param {Object} config
 * @param {Array} config.additionalDataNew
 * @param {Array} config.additionalDataOld
 * @param {Array} config.dataItems
 * @param {String} config.language
 */
export const additionalDataMerge = (config = {}) => {
  const {
    additionalDataNew = [],
    additionalDataOld = [],
    dataItems = [],
    language = ''
  } = config

  const aDataOld = cloneDeep(
    additionalDataOld
      .filter((ado) => ado.language && ado.language !== language)
      .map((ad) => omit(ad, ['_id']))
  )

  additionalDataNew.forEach(({ key, value }) => {
    const valueType = typeof value

    if (valueType === 'boolean' || valueType === 'number' || value === '') {
      const dataItemsObj = dataItems.find((di) => di[language] === key)

      if (!dataItemsObj) {
        return
      }

      const otherLang = language === 'en' ? 'it' : 'en'
      const adOldKey = dataItemsObj[otherLang]
      const aDataOldIdx = aDataOld.findIndex((ado) => ado.key === adOldKey)

      if (aDataOldIdx < 0) {
        additionalDataNew.push({
          key: adOldKey,
          language: otherLang,
          value
        })

        return
      }

      const aDataOldValueType = typeof aDataOld[aDataOldIdx].value

      if (
        aDataOldValueType === 'boolean' ||
        aDataOldValueType === 'number' ||
        aDataOld[aDataOldIdx].value === ''
      ) {
        aDataOld[aDataOldIdx].value = value
      }
    }
  })

  return [...additionalDataNew, ...aDataOld]
}

export const additionalFieldName = (name = '') => `additionalData-${name}`

/**
 * @param {Object} data
 * @param {Array} data.products
 * @param {String} data.vendorId
 */
export const filterByVendorId = (data = {}) => {
  const { products = [], vendorId = '' } = data

  return products.filter(
    (product) =>
      skuFromProductId(product._id) === vendorId && !product.discontinuedOn
  )
}

/**
 * @param {Object} config
 * @param {String} config.format - WxDxH
 * @param {Object} config.product
 */
export const formatDimensions = (config = {}) => {
  const SPLITTER = 'x'
  const { format = 'WxDxH', product = {} } = config
  const { dimensions } = product || {}

  return dimensions
    ? format
        .split(SPLITTER)
        .map((dimension) => dimensions[dimension])
        .filter((dimension) => dimension !== undefined)
        .join(SPLITTER)
    : ''
}

/**
 * @param {Object} product
 * @param {String} product.mainPhoto
 * @param {String} product.onboardingPhoto
 */
export const getPhotoSrc = (product = {}) => {
  const mainSrc = get(product, 'mainPhoto', '')
  const onboardingSrc = get(product, 'onboardingPhoto', '')

  return mainSrc || onboardingSrc || ''
}

/**
 * @param {Array} products
 * @param {Object} products[].translation
 * @param {String} products[].translation.status
 */
export const groupByTranslationStatus = (products = []) => ({
  ...groupBy(products, 'translation.status')
})

/**
 * @param {String} userType
 */
export const allowDisapprovedByUserType = (userType = '') => {
  return userType === USER_TYPES.ADMIN || userType === USER_TYPES.APPROVER
}

export const CERTIFICATIONS = ['CARB', 'UL']

export const certificationsFromValues = (values = {}) => {
  const { certificationCARB, certificationUL } = values
  const certifications = []

  if (certificationCARB) {
    certifications.push(CERTIFICATIONS[0])
  }
  if (certificationUL) {
    certifications.push(CERTIFICATIONS[1])
  }

  return certifications
}

/**
 * @param {Number} eurValue
 */
export const countEurToUsd = (eurValue) => eurValue * CURRENCY_CONVERT.EUR_USD

/**
 * @param {Object} data
 * @param {Number} data.final
 * @param {Number} data.finalShipping
 * @param {Number} data.fiscalRegimenPercent
 * @param {Boolean} data.isTotal
 * @param {Number} data.shipping
 * @param {Number} data.wholeSale
 * @param {Number} data.woodenCrate
 */
export const countMargin = (data = {}) => {
  const {
    final = 0,
    finalShipping = 0,
    fiscalRegimenPercent = 0,
    isTotal = false,
    shipping = 0,
    wholeSale = 0,
    woodenCrate = 0
  } = data

  const finalCost =
    wholeSale * (1 + fiscalRegimenPercent / 100) + shipping + woodenCrate
  const finalTotal = final + finalShipping
  const marginTotal = finalTotal - finalCost

  if (isTotal) {
    return marginTotal.toFixed(2)
  }

  const marginPercentage = finalTotal ? (marginTotal / finalTotal) * 100 : 0

  return marginPercentage.toFixed(2)
}

export const getAdditionalData = (product = {}) =>
  get(product, 'additionalData') || []

export const getAdditionalDataByKey = ({ key = '', product = {} }) => {
  const additionalData = getAdditionalData(product)

  return additionalData.find((ad) => ad.key === key) || {}
}

/**
 * @param {Object} product
 * @param {Object} product.vendorCollection
 * @param {String} product.vendorCollection.id
 * @param {String} product.vendorCollection._id
 */
export const getCollectionId = (product = {}) => {
  const vc = get(product, 'vendorCollection', {})

  if (typeof vc === 'string') {
    return vc
  }

  const { id, _id } = vc

  return id || _id
}

/**
 * @param {Object} config
 * @param {String} config.language
 * @param {Object} config.product
 * @param {Object} config.product.type
 * @param {Array} config.product.type.dataItems
 * @param {String} config.product.type.dataItems[].en
 * @param {String} config.product.type.dataItems[].it
 * @param {String} config.product.type.dataItems[].type
 * @param {Boolean} config.woodenCrateNeeded
 */
export const getDataItems = (config = {}) => {
  const { language = '', product = {}, woodenCrateNeeded = false } = config

  const res = get(product, 'type.dataItems', []).map(
    ({ type, ...dataItem }) => ({
      name: dataItem[language] || '',
      type
    })
  )

  if (!woodenCrateNeeded) {
    return res
  }

  const hasWoodenCrate = !!res.find((f) => f.name === WOODEN_CRATE[language])

  if (hasWoodenCrate) {
    return res
  }

  res.push({
    name: WOODEN_CRATE[language],
    type: 'number'
  })

  return res
}

/**
 * @param {Object} config
 * @param {String} config.language
 * @param {Object} config.product
 * @param {Boolean} config.woodenCrateNeeded
 */
export const additionalFieldsInitialValues = (config = {}) => {
  const { product } = config
  const dataItems = getDataItems(config)

  return dataItems.reduce((acc, { name, type } = {}) => {
    const fieldName = additionalFieldName(name)
    const { value } = getAdditionalDataByKey({
      key: name,
      product
    })

    switch (type) {
      case 'boolean':
        acc[fieldName] = Boolean(value || false)
        break
      case 'number':
        acc[fieldName] = value || 0
        break
      default:
        acc[fieldName] = value || ''
    }

    return acc
  }, {})
}

/**
 * This function takes the values of the product form
 * and return a function that transforms the additionalData values
 * so that Wooden Crate parameter is always the same in both languages
 *
 * @param {Object} values // The product form values
 * @returns {Function} // The function that is passed to `map` in the additional data to transform values
 */
export const additionalDataWoodenCrateOverwrite = (values) => {
  const woodenCrateLabels = Object.values(WOODEN_CRATE)
  const woodenCrateValue = woodenCrateLabels
    .map((label) => {
      return values[`additionalData-${label}`]
    })
    .filter((item) => item)[0]
  return (item) => {
    if (woodenCrateLabels.includes(item.key)) {
      item.value = woodenCrateValue
    }
    return item
  }
}

export const getProductsPending = (products = []) =>
  products.filter((product) => product.status === STATUSES.PENDING)

/**
 * @param {Object} product
 * @param {String} product.status
 */
export const isProductApproved = (product = {}) =>
  get(product, 'status', '') === STATUSES.APPROVED

/**
 * @param {Object} product
 * @param {String} product.status
 */
export const isProductPending = (product = {}) =>
  get(product, 'status', '') === STATUSES.PENDING

/**
 * @param {Array} products
 * @param {Object} product[]
 */
export const mapForPaginator = (products = []) =>
  products.map((product) => {
    const {
      _id,
      mainPhoto,
      name,
      onboardingPhoto,
      saved,
      status,
      discontinuedOn
    } = product
    const nameProp = name || _id || ''

    return {
      _id,
      alt: nameProp,
      imageUrl: mainPhoto || onboardingPhoto,
      name,
      path: `/product-detail/${_id}`,
      saved,
      status,
      discontinuedOn
    }
  })

/**
 * @param {Object} config
 * @param {String} config.lang
 * @param {Array} config.productTypes
 * @param {Object} config.productTypes[].name
 * @param {String} config.productTypes[].name.en
 * @param {String} config.productTypes[].name.it
 * @param {String} config.productTypes[]._id
 */
export const mapProductTypes = (config = {}) => {
  const { lang = '', productTypes = [] } = config

  return productTypes
    .map(({ name, _id }) => ({
      id: _id,
      name: name[lang] || name.en
    }))
    .filter(({ name }) => name)
}

/**
 * @param {Array} products
 * @param {String} products[]._id
 */
export const mapSearchedProducts = (products = []) => {
  return products.map((product) => ({
    ...pick(product, ['name', '_id']),
    image: product.mainPhoto,
    sku: product._id,
    vendor: get(product, 'vendor.artisanName', '')
  }))
}

/**
 * @param {Object} config
 * @param {Object} config.intl
 * @param {Function} config.intl.formatMessage
 * @param {Number} config.number
 */
export const parcelNumber = (config = {}) => {
  const { intl = {}, number = 1 } = config
  const formatMessage = get(intl, 'formatMessage', noop)
  const parcelWord = formatMessage({ id: 'Parcel' })

  return `${parcelWord}#${number}`
}

/**
 * @param {Object} data
 * @param {Object} data.product
 * @param {Array} data.product.parcels
 * @param {Object} data.product.parcels[]
 * @param {Boolean} data.showAmounts
 */
export const parcelsToList = ({ product = {}, showAmounts = false }) => {
  let amountCurrency = ''
  const parcels = get(product, 'parcels') || []

  return parcels.map((parcel) => {
    if (showAmounts) {
      const { amount: euAmount, currency: euCurrency } =
        get(parcel, 'shipmentData.euShipment') || {}
      const { amount_local: usAmountLocal, currency_local: usCurrencyLocal } =
        get(parcel, 'shipmentData.usShipment') || {}

      amountCurrency = `${euAmount} ${euCurrency}, ${usAmountLocal} ${usCurrencyLocal}`
    }

    return {
      ...parcel,
      amountCurrency
    }
  })
}

export const parseParcelNumber = (parcelNumber = '') => {
  const parsed = parcelNumber.split('#')

  return {
    number: parseFloat(parsed[parsed.length - 1]) || 0
  }
}

/**
 * @param {Object} config
 * @param {String} config.currency
 * @param {Object} config.product
 */
export const pricesByCurrency = (config = {}) => {
  const { currency, product } = config
  const prices = get(product, `pricing.${currency}`) || {}
  const { final = 0, finalShipping = 0 } = prices

  return {
    ...prices,
    final: parseFloat(final),
    finalShipping: parseFloat(finalShipping)
  }
}

/**
 * @param {Object} product
 * @param {String} product.onboardingPhoto
 */
export const productPhotos = (product = {}) => ({
  main: get(product, 'mainPhoto', ''),
  onboarding: get(product, 'onboardingPhoto', ''),
  other: get(product, 'photos') || []
})

/**
 * @param {Object} config
 * @param {String} config.by
 * @param {String} config.order - ORDER_BY from ./constants
 * @param {Array} config.products
 * @param {Object} config.products[]
 */
export const sortProducts = (config = {}) => {
  const { by = '_id', order = ORDER_BY.DESC, products = [] } = config

  return [...products].sort((p1, p2) => {
    if (p1[by] > p2[by]) {
      return order === ORDER_BY.DESC ? -1 : 1
    }

    return order === ORDER_BY.DESC ? 1 : -1
  })
}

export const sortProdsByIdDesc = (p1, p2) => {
  return p1._id > p2._id ? -1 : 1
}

export const sortProdTypesByNameAsc = (a, b) => {
  return a.name < b.name ? -1 : 1
}

/**
 * @param {Object} config
 * @param {String} config.lang
 * @param {Object} config.product
 * @param {Object} config.product.type
 * @param {Object} config.product.type.name
 * @param {String} config.product.type.name[lang]
 */
export const typeNameByLang = (config = {}) => {
  const { lang = '', product = {} } = config
  const { type } = product || {}

  return get(type, `name.${lang}`) || get(type, 'name.en', '')
}

/**
 * @param {Object} config
 * @param {Object} config.intl
 * @param {Function} config.intl.formatMessage
 * @param {Array} config.properties
 * @param {String} config.properties[].name
 */
export const propertiesToOptions = (config = {}) => {
  const { intl = {}, properties = [] } = config
  const formatMessage = get(intl, 'formatMessage')

  return properties.map(({ name }) => ({
    label: formatMessage ? formatMessage({ id: name }) : name,
    value: name
  }))
}

export const shippingInOptions = (config = {}) => {
  const { intl = {} } = config
  const formatMessage = get(intl, 'formatMessage')

  return range(1, 19).map((num) => ({
    label: `${formatMessage({
      id: 'Ships in'
    })} ${formatMessage(
      {
        id: 'WeeksAmount'
      },
      { amount: num }
    )}`,
    value: num
  }))
}

/**
 * @param {Object} pricing
 * @param {Object} pricing[currency]
 * @param {Number} pricing[currency].final
 * @param {Number} pricing[currency].finalShipping
 */
const currencies = ['EUR', 'USD', 'GBP']
export const pricingShippingFinal = (pricing = {}) =>
  currencies
    .map((currency) => {
      const obj = pricing[currency]
      if (!obj) return undefined

      return {
        currency,
        price: parseFloat(obj.final) || 0,
        shipping: parseFloat(obj.finalShipping) || 0
      }
    })
    .filter((obj) => obj)

/**
 * @param {Object} values
 * @returns {Object}
 */
export const validateProduct = (values = {}) => {
  const { D, H, productName, retailPrice, W, weight } = values

  const errors = {}
  const dParsed = parseInt(D)
  const hParsed = parseInt(H)
  const retailPriceParsed = parseInt(retailPrice)
  const weightParsed = parseInt(weight)
  const wParsed = parseInt(W)

  const forNumbers = (parsedValue, propName) => {
    if (isNaN(parsedValue)) {
      errors[propName] = ERRORS.REQUIRED
    } else if (parsedValue <= 0) {
      errors[propName] = ERRORS.MUST_BE_POSITIVE
    }
  }

  if (!productName) {
    errors.productName = ERRORS.REQUIRED
  }

  forNumbers(retailPriceParsed, 'retailPrice')
  forNumbers(weightParsed, 'weight')
  forNumbers(wParsed, 'W')
  forNumbers(dParsed, 'D')
  forNumbers(hParsed, 'H')

  return errors
}
