import { Tooltip } from 'components'
import { noop } from 'lodash'
import { bool, number, shape, string } from 'prop-types'
import React from 'react'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { ERRORS, USER_TYPES, WOODEN_CRATE } from 'utils/constants'
import { certificationValues, checkboxFieldsList } from 'utils/form'
import { additionalFieldName, CERTIFICATIONS } from 'utils/products'

import st from './ProductDetailForm.module.css'

export const additionalFields = ({
  blocked = false,
  dataItems = [],
  decimalSign = '.',
  formatMessage = noop,
  lang = 'en',
  userType = ''
}) =>
  dataItems.map(({ name, type }) => {
    const fieldName = additionalFieldName(name)
    const data = {
      label:
        name === WOODEN_CRATE[lang]
          ? formatMessage({ id: WOODEN_CRATE.en })
          : name,
      name: fieldName,
      placeholder: name,
      type
    }

    if (
      blocked &&
      name === WOODEN_CRATE[lang] &&
      userType === USER_TYPES.VENDOR
    ) {
      data.disabled = true
    }

    if (type === 'boolean') {
      data.horizontal = true
      data.reversed = true
      data.type = 'checkbox'
    }

    if (type === 'number') {
      data.decimalSign = decimalSign
      data.separateNumberValue = true
    }

    return data
  })

export const certificationsFields = () =>
  checkboxFieldsList(
    CERTIFICATIONS.map((certification) => {
      const item = {
        label: certification,
        name: `certification${certification}`
      }

      if (certification === CERTIFICATIONS[0]) {
        item.labelAfter = (
          <Tooltip
            id={`tooltip-${certification}`}
            tooltip="CARB is a regulation for US and Canada disciplining maximum levels of emissions for formaldehyde in composite wood products"
          >
            <AiFillQuestionCircle className={st.popup} />
          </Tooltip>
        )
      }

      return item
    })
  )

export const colsWidth = { md: 6, sm: 12 }

export const dimensionsFields = ({ decimalSign = '.', intl = {} }) => {
  const items = [
    {
      name: 'W',
      placeholder: intl.formatMessage({ id: 'Width' }),
      type: 'number'
    },
    {
      name: 'D',
      placeholder: intl.formatMessage({ id: 'Depth' }),
      type: 'number'
    },
    {
      name: 'H',
      placeholder: intl.formatMessage({ id: 'Height' }),
      type: 'number'
    }
  ]

  return items.map((df) => ({
    ...df,
    decimalSign,
    isScrollTo: true,
    separateNumberValue: true
  }))
}

export const ERRORS_ORDER = [
  'productName',
  'description',
  'mainColor',
  'material',
  'secondaryMaterial',
  'wholesalePrice',
  'retailPrice',
  'weight',
  'W',
  'D',
  'H',
  'parcels'
]

export const INITIAL_VALUES = {
  DEFAULT_PROPS: {
    ...certificationValues.default,
    canBeCustomized: false,
    canBeRemade: false,
    comments: '',
    D: 0,
    dedicatedCourier: false,
    description: '',
    designer: '',
    fiscalRegimen: '',
    H: 0,
    isCITES: false,
    mainColor: '',
    markForTranslation: false,
    material: '',
    secondaryMaterial: '',
    needsAssembly: false,
    productName: '',
    remadeDays: 0,
    retailPrice: 0,
    shippingIn: 0,
    vendorCode: '',
    // externalId: '',
    W: 0,
    weight: 0,
    wholesalePrice: 0
  },
  PROP_TYPES: shape({
    ...certificationValues.types,
    canBeCustomized: bool,
    canBeRemade: bool,
    comments: string,
    D: number,
    dedicatedCourier: bool,
    description: string,
    designer: string,
    fiscalRegimen: string,
    H: number,
    isCITES: bool,
    mainColor: string,
    markForTranslation: bool,
    material: string,
    secondaryMaterial: string,
    needsAssembly: bool,
    productName: string,
    remadeDays: number,
    retailPrice: number,
    shippingIn: number,
    vendorCode: string,
    externalId: string,
    internalUseOnly: bool,
    W: number,
    weight: number,
    wholesalePrice: number
  })
}

export const leftFields2 = ({
  blocked = false,
  decimalSign = '.',
  intl = {},
  userType = ''
}) => [
  {
    decimalSign,
    disabled: blocked && userType === USER_TYPES.VENDOR,
    isScrollTo: true,
    label: intl.formatMessage({ id: 'Wholesale Price' }),
    name: 'wholesalePrice',
    placeholder: intl.formatMessage({ id: 'Type price here' }),
    separateNumberValue: true,
    type: 'number'
  },
  {
    disabled: true,
    label: intl.formatMessage({ id: 'fiscalRegimen' }),
    name: 'fiscalRegimen',
    type: 'string'
  }
]

export const moreInfoFields = ({
  intl = {},
  nextNodeRemade = null,
  leadTimeCustom = null
}) => {
  return [
    ...checkboxFieldsList([
      {
        label: intl.formatMessage({ id: 'Needs assembly' }),
        name: 'needsAssembly',
        labelAfter: (
          <Tooltip
            id="needsAssemblyTooltip"
            tooltip={intl.formatMessage({
              id: 'needsAssemblyMsg'
            })}
          >
            <AiFillQuestionCircle className={st.popup} />
          </Tooltip>
        )
      },
      {
        label: intl.formatMessage({ id: 'Can be remade in' }),
        name: 'canBeRemade',
        nextNode: nextNodeRemade
      }
    ]),
    {
      isScrollTo: true,
      label: intl.formatMessage({ id: 'Lead time' }),
      name: 'shippingInField',
      type: 'number',
      min: '1',
      customField: leadTimeCustom,
      labelAfter: (
        <Tooltip
          id="shippingIn"
          tooltip={intl.formatMessage({
            id: 'current lead time, considering company closure times or specific production delays'
          })}
        >
          <AiFillQuestionCircle className={st.popup} />
        </Tooltip>
      )
    },
    ...checkboxFieldsList([
      {
        label: intl.formatMessage({ id: 'Can be customized' }),
        name: 'canBeCustomized'
      }
    ])
  ]
}

/**
 * @param {Object} config
 * @param {Function} config.clickIsCitiesAfter
 * @param {Object} config.intl
 * @param {Function} config.intl.formatMessage
 * @param {String} config.userType
 */
export const postFields = ({ intl = {}, userType = '' }) => {
  const isCITES = 'isCITES'
  const fieldsList = [
    {
      label: intl.formatMessage({ id: 'Is Cites' }),
      labelAfter: (
        <Tooltip
          id={isCITES}
          tooltip="Cites is a multilateral treaty to protect endangered plants and animals."
        >
          <AiFillQuestionCircle className={st.popup} />
        </Tooltip>
      ),
      name: isCITES
    }
  ]

  if (userType === USER_TYPES.ADMIN) {
    fieldsList.push({
      label: intl.formatMessage({ id: 'dedicatedCourier' }),
      name: 'dedicatedCourier'
    })
  }

  return checkboxFieldsList(fieldsList)
}

export const rightFields = ({ designerOptions = [], intl = {} }) => {
  const placeholderSelect = intl.formatMessage({ id: 'Select' })
  return [
    {
      isScrollTo: true,
      label: `${intl.formatMessage({ id: 'Product Name' })} *`,
      name: 'productName',
      placeholder: intl.formatMessage({ id: 'Type product name here' })
    },
    {
      label: intl.formatMessage({ id: 'Vendor code' }),
      name: 'vendorCode',
      placeholder: intl.formatMessage({ id: 'Type code here' })
    },
    {
      inputClassName: st.description,
      isScrollTo: true,
      label: `${intl.formatMessage({ id: 'Description' })} *`,
      name: 'description',
      placeholder: intl.formatMessage({ id: 'Type message here' }),
      type: 'textarea'
    },
    {
      label: intl.formatMessage({ id: 'Designer' }),
      name: 'designer',
      options: designerOptions,
      placeholder: placeholderSelect,
      allowEmpty: true
    }
  ]
}

export const rightFields2 = ({
  blocked = false,
  decimalSign = '.',
  intl = {},
  userType = ''
}) => [
  {
    decimalSign,
    disabled: blocked && userType === USER_TYPES.VENDOR,
    isScrollTo: true,
    label: intl.formatMessage({ id: 'Retail price' }),
    name: 'retailPrice',
    placeholder: intl.formatMessage({ id: 'Type price here' }),
    separateNumberValue: true,
    type: 'number'
  }
]

export const mainColor = ({ colorOptions = [], intl = {} }) => {
  const placeholderSelect = intl.formatMessage({ id: 'Select' })

  return [
    {
      isScrollTo: true,
      label: `${intl.formatMessage({ id: 'Main color' })} *`,
      name: 'mainColor',
      options: colorOptions,
      placeholder: placeholderSelect
    }
  ]
}

export const materials = ({ intl = {}, materialOptions = [] }) => {
  const placeholderSelect = intl.formatMessage({ id: 'Select' })

  return [
    {
      isScrollTo: true,
      label: `${intl.formatMessage({ id: 'Material' })} *`,
      name: 'material',
      options: materialOptions,
      placeholder: placeholderSelect
    },
    {
      isScrollTo: true,
      label: intl.formatMessage({ id: 'Secondary Material' }),
      name: 'secondaryMaterial',
      options: materialOptions,
      placeholder: placeholderSelect,
      allowEmpty: true
    }
  ]
}

export const translationBlockFields = ({ intl = {} }) =>
  checkboxFieldsList([
    {
      label: intl.formatMessage({ id: 'Mark for translation' }),
      name: 'markForTranslation'
    },
    {
      label: intl.formatMessage({ id: 'Block product' }),
      name: 'blocked'
    }
  ]).map((field) => ({
    ...field,
    style: {
      alignItems: 'center',
      display: 'flex',
      marginBottom: 0
    }
  }))

export const validate = (values = {}) => {
  const {
    D,
    description,
    H,
    mainColor,
    material,
    secondaryMaterial,
    productName,
    retailPrice,
    W,
    weight,
    wholesalePrice,
    fiscalRegimen,
    stockQuantity,
    shippingIn,
    packagingTime
  } = values
  const fiscalRegimenValue = parseInt(fiscalRegimen.slice(0, -1))
  const errors = {}

  if (!D && D <= 0) {
    errors.D = ERRORS.MUST_BE_POSITIVE
  }
  if (!description) {
    errors.description = ERRORS.REQUIRED
  }
  if (!H && H <= 0) {
    errors.H = ERRORS.MUST_BE_POSITIVE
  }
  if (!mainColor) {
    errors.mainColor = ERRORS.REQUIRED
  }
  if (!material) {
    errors.material = ERRORS.REQUIRED
  }
  if (material && secondaryMaterial === material) {
    errors.secondaryMaterial = ERRORS.DIFFERENT_MATERIAL
  }
  if (!productName) {
    errors.productName = ERRORS.REQUIRED
  }
  if (retailPrice <= 0) {
    errors.retailPrice = ERRORS.MUST_BE_POSITIVE
  }
  if (!W && W <= 0) {
    errors.W = ERRORS.MUST_BE_POSITIVE
  }
  if (!weight && weight <= 0) {
    errors.weight = ERRORS.MUST_BE_POSITIVE
  }
  if (wholesalePrice <= 0) {
    errors.wholesalePrice = ERRORS.MUST_BE_POSITIVE
  }
  if (stockQuantity === '') {
    errors.stockQuantity = ERRORS.REQUIRED
  } else if (stockQuantity < 0) {
    errors.stockQuantity = ERRORS.MUST_BE_POSITIVE
  }
  if (shippingIn < 1) {
    errors.shippingIn = ERRORS.MUST_BE_POSITIVE
  }
  if (packagingTime === '') {
    errors.packagingTime = ERRORS.REQUIRED
  } else if (packagingTime < 0) {
    errors.packagingTime = ERRORS.MUST_BE_POSITIVE
  }

  const wholesaleTaxMultiplier = 1 + fiscalRegimenValue / 100
  const wholesaleWithTax = wholesalePrice * wholesaleTaxMultiplier
  const wholesalePercentage = (100 * wholesaleWithTax) / retailPrice
  // AVT-568: If the wholesale price with tax is not lower than 60% of retail price we display the Error
  if (wholesalePercentage > 60) {
    errors.wholesalePrice = ERRORS.DISCOUNT_TOO_LOW
    errors.retailPrice = ERRORS.DISCOUNT_TOO_LOW
  }

  return errors
}

export const weightColsWidth = { sm: 4, xs: 12 }
