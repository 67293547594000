import { Button } from 'components/buttons'
import Spinner from 'components/Spinner'
import { noop } from 'lodash'
import { func, shape, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Col, CustomInput, Input, Row } from 'reactstrap'

import Style from '../../pages/styles/merchandising.module.css'

const MerchElements = ({
  product,
  intl: { formatMessage },
  selectedProductIds,
  changeSelected,
  changeShippingIn
}) => {
  const { _id, mainPhoto, shippingIn } = product

  const [value, setValue] = useState(shippingIn)
  const [isChangeInProgress, setIsChangeInProgress] = useState(false)
  const [showError, setShowError] = useState(value < 1)

  useEffect(() => {
    setValue(shippingIn)
  }, [shippingIn])

  useEffect(() => {
    setShowError(value < 1)
  }, [value])

  const handleInputChange = (val) => {
    setValue(val)
    setShowError(val < 1)
  }
  return (
    <Col key={_id} md={6}>
      <Row className={Style.mainRow}>
        <Col md={6}>
          <div className={Style.imageWrap}>
            <CustomInput
              checked={Boolean(selectedProductIds[_id])}
              className={Style.checkbox}
              type="checkbox"
              id={_id}
              onChange={changeSelected.bind(this, _id)}
            />
            <img className={Style.image} src={mainPhoto} alt="product" />
          </div>
        </Col>
        <Col className={Style.data} md={6}>
          <Row>
            <h4>
              <Link to={`/product-detail/${_id}`}>{_id}</Link>
            </h4>
          </Row>
          <Row>
            <div className={`${Style.leadTimeInput} `}>
              <span>
                {formatMessage({
                  id: 'Ships in'
                })}
              </span>
              <Input
                className={Style.daysInput}
                onChange={(e) => handleInputChange(e.target.value)}
                type="number"
                min="1"
                value={value}
                invalid={value < 1}
              />
              <span>
                {formatMessage({
                  id: 'weeks'
                })}
              </span>
            </div>
            {showError && (
              <div className={Style.error}>
                {formatMessage({
                  id: 'Please enter a value greater than or equal to 1'
                })}
              </div>
            )}
          </Row>
          <Row
            className={`${
              showError || isChangeInProgress ? Style.disabled : ''
            }`}
          >
            <Button
              onClick={async () => {
                setIsChangeInProgress(true)
                await changeShippingIn(_id, value)
                setIsChangeInProgress(false)
              }}
              type="button"
            >
              {isChangeInProgress ? (
                <Spinner
                  color="--main-bg-light"
                  width={43}
                  length={3}
                  size={14}
                  show
                />
              ) : (
                formatMessage({
                  id: 'Update'
                })
              )}
            </Button>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}

MerchElements.defaultProps = {
  elements: [],
  selectedProductIds: {},
  changeAcceptDiscount: noop,
  changeSelected: noop,
  changeShippingIn: noop
}
MerchElements.propTypes = {
  product: shape({
    _id: string.isRequired,
    mainPhoto: string,
    shippingIn: string
  }),
  selectedProductIds: shape({}),
  changeAcceptDiscount: func,
  changeSelected: func,
  changeShippingIn: func,
  intl: shape({
    formatMessage: func
  })
}

export default injectIntl(MerchElements)
